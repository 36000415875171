$(document).ready(function(){
	
	
	// Top Button Fixed
	
	var $window = $(window);

	var offsetTop = $window.height();

	var $top = $('<div class="top-scroll__fixed"><span class="sr-only">Zum Seitenanfang</span></div>').appendTo('body');

	$top.on('click', function (event) {

		$('body').velocity("scroll", {
			duration: 800
		});

	});



	function topVisible (event) {
	
		var windowScrollTop = $window.scrollTop();

		if(windowScrollTop > offsetTop && topVisible.state !== true ){

			topVisible.state = true;

			//$top.addClass('[ js is-visible ]');
			$top.addClass('is-visible');

		} else if (windowScrollTop <= offsetTop && topVisible.state !== false){

			topVisible.state = false;

			//$top.removeClass('[ js is-visible ]');
			$top.removeClass('is-visible');
		}
	}

	topVisible.state = undefined;

	$window.on('scroll.top', topVisible);

	topVisible();
	
	
	
	
	// Top Link Inline
	/*
	$(".top-scroll a").on('click', function (event) {
		
		event.preventDefault();
		
		$('body').velocity("scroll", {
			duration: 800
		});

	});
	*/
	
	
});
