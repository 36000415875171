$(document).ready(function (){
    
    
    var $button;
    
	uploadInit();
    
	
	
	
	function bindMultipleDelete() {
		
		$(".multiple__delete").on("click", function(event){
			event.preventDefault();
			
			// 
			$button = $(this);
			
			if ( $button.attr("data-multi-argument") ) {
				$template_path = $button.attr("data-multi-path") + "&" + $button.attr("data-multi-argument") + "=" + $button.attr("data-multi-id");
			
				$.ajax({
					url: $template_path
				})
				.done(function( html ) {
					
					// $button.parents(".multiple__wrap").remove();
	
				});
			
			} else {
				
				// $button.parents(".multiple__wrap").remove();
			
			}
			
			$button.parents(".multiple__wrap").remove();
			
		});
		
	}
    
    
    
	function uploadInit () {
		
		
		$('.upload input[type=file]:not(.upload-initialised)').each(function () {
			
			var eventNamespace = '.upload';
			var labelInputValueAttr = 'data-input-value';
			var $input = $(this);
			$input.removeClass('empty');
			var $inputClone = $input.clone(true,true);
			var $label = $input.next('label');
			
			var setLabelInputValue = function () {
				var $input = $(this);
				
				if($input.val() && $input.val() !== ''){
					$input.removeClass('empty');
					$label.attr(labelInputValueAttr, $input.val().split('\\').pop());
				} else {
					$label.attr(labelInputValueAttr, '');
					$input.addClass('empty');
				}
			}
			
			if(!$input.val() || $input.val() === ''){
				$input.addClass('empty');
			}
			
			$label.attr(labelInputValueAttr,'');
			
			$input.on('change' + eventNamespace, setLabelInputValue);
			
			$label.on('click' + eventNamespace, function (event) {
				
				if($input.val() && $input.val() !== '' && $input.is(':valid')){
					
					event.preventDefault();
					$input.remove();
					$label.before($inputClone); // cant just empty val because of ie
					$input = $inputClone;
					
					if(!$input.val() || $input.val() === ''){
						$input.addClass('empty');
					}
					
					$inputClone = $input.clone(true,true);
					
					$inputClone.removeClass('empty');
					
					$input.off('change' + eventNamespace);
					$input.on('change' + eventNamespace, setLabelInputValue);
					$label.attr(labelInputValueAttr,'');
				}
			});
			
			$input.addClass('upload-initialised');
		});
	}
	
	
    
    (function () {
		
		bindMultipleDelete();
		
		
		$("div[data-multi-btn]").on("click",function(event){
			event.preventDefault();
			
			$button = $(this);
			
			if ( $(this).attr("data-multi-argument") ) {
				$template_path = $(this).attr("data-multi-path") + "&" + $(this).attr("data-multi-argument") + "=" + $(this).attr("data-multi-iteration");
			} else {
				$template_path = $(this).attr("data-multi-path");
			}
			/* $template = $( "#tpl_" + $(this).attr("data-multi-tpl") + " .multiple__wrap" ).clone(); */
			
			$container = $( "#" + $(this).attr("data-multi-cont") );
			
			
			$.ajax({
				url: $template_path
			})
			.done(function( html ) {
				$container.append( html );
				bindMultipleDelete();
				
				$button.attr("data-multi-iteration", ( parseInt($button.attr("data-multi-iteration") ) + 1 ) );
				uploadInit();
				
			});
			
		});
	
	})();
    
    
});

/*
// DROPAREA
$(document).ready(function () {

  var uploadedText = 'Folgende Dateien wurden ausgewählt';
  var fileextText = 'Bitte beachten Sie das zulässige Dateiformat';
  var filesizeText = 'und die max. Dateigröße';

  if (!window.File || !window.FileList || !window.FileReader) {
    return;
  }

  function formatBytes(bytes, decimals) {
    if(bytes == 0) return '0 Bytes';
    var k = 1000,
        dm = decimals + 1 || 3,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  $('.droparea > input[type="file"]').each(function () {
    var $this = $(this);
    var $droparea = $(this).parent('.droparea');
    var $text = $droparea.find('span');
    var $clear = $droparea.find('.clear');
    var initialText = $text.html() || '';
    var accept = (($this.attr('accept') || '').toLowerCase().replace(/\s/g, '').replace(/\./g, '')).split(',');
    var maxsize = parseInt($this.attr('data-maxfilesize') || 0, 10);
    
    $clear.on('click', function (event) {
      event.preventDefault();
      $this.val('');
      $this.replaceWith($this.clone(true)); // ie
      $droparea.next('label.error').remove();
      $droparea.removeClass('error');
      $droparea.find('.error').removeClass('error');
      $text.html(initialText);
      $clear.addClass('hidden');
    });

    $this.on('dragover dragleave', function (event) {
      event.stopPropagation();
      event.preventDefault();
      if (event.type === "dragover") {
        $droparea.addClass('dragover');
      } else {
        $droparea.removeClass('dragover');
      }
    });

    $this.on('change', function (event) {
      var files = $(this).get(0).files;
      var size = 0;
      var fileString = '';
      var invalid = false;

      $droparea.removeClass('error');
      $droparea.removeClass('dragover');
      $droparea.next('label.error').remove();
      if (files && files.length > 0) {
        fileString = _.map(files, function (value, key) {
          size = size + value.size;
          if(accept.indexOf(value.name.split('.').pop()) === -1) {
            invalid = true;
          }
          return value.name;
        }).join(', ');
        if (size >= maxsize) {
          invalid = true;
        }
        $text.html(uploadedText + ' (' + formatBytes(size, 0) + '):<br>' + fileString);
        if (invalid) {
          $droparea.addClass('error');
          $('<label id="file-error" class="error" for="' + $this.attr('id') + '">'+ fileextText +' ('+ accept.join(', ') +') '+ filesizeText+' ('+ formatBytes(maxsize, 0) +').</label>').insertAfter($droparea);
        }
        $clear.removeClass('hidden');
      } else {
        $text.html(initialText);
        $clear.addClass('hidden');
      }
    });
  });
});
*/