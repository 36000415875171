

// navigation-main: hover intent

$(document).ready(function () {

    var $navLis = $('.first-level__item');

    function over () {
        $(this).addClass('hoverIntent')
    }

    function out () {
        $(this).removeClass('hoverIntent')
    }

    $navLis.addClass('nohover');

    $navLis.hoverIntent({
        over: over,
        out: out,
        timeout: 250
    });
});



// Accessible toggle for second level of main nav

$(document).ready(function () {

	$('.sub-toggle').on('click', function(e) {

		if ( $(this).parent('a').attr( 'aria-expanded' ) === 'false' ) {
			$(this).parent('a').attr( 'aria-expanded', 'true' );
			$(this).parent().parent('.first-level__item').addClass('hoverIntent');
			$(this).children().children('span').text('Menü schließen');
		} else {
			$(this).parent('a').attr( 'aria-expanded', 'false' );
			$(this).parent().parent().removeClass('hoverIntent');
			$(this).children().children('span').text('Menü öffnen');
		}

		e.preventDefault();
		return false

	});
});







// Accessible toggle for mobile nav

$(document).ready(function () {

	var $slideNavCheckbox = $('#slide-nav-checkbox-root');
	var $slideNavToggle = $('#slide-nav-trigger');

	// uncheckAll();

	$slideNavToggle.on('click', function(e) {

		if ( $slideNavCheckbox.prop( 'checked' )  === false ) {
			$slideNavCheckbox.prop( 'checked', true );
			$slideNavToggle.children('span').text('Menü schließen');

			// console.log('new toggle is clicked to open');
			// console.log('root checkbox is ' + $slideNavCheckbox.prop( 'checked' ) );

		} else {
			$slideNavCheckbox.prop( 'checked', false );
			$slideNavToggle.children('span').text('Menü öffnen');

			// console.log('new toggle is clicked to close');
			// console.log('root checkbox is ' + $slideNavCheckbox.prop( 'checked' ) );
		}

		e.preventDefault();
		return false

	});
});



// navigation-mobile: close all sub-navs on close

$(document).ready(function () {

	var $body = $('body');
	var $nav = $('.slide-nav-root');
	var $navCheckboxes = $nav.find('.slide-nav-checkbox');
	var $rootCheckbox = $('#slide-nav-checkbox-root');

	$rootCheckbox.on('change', function (event) {

		if ( $rootCheckbox.prop( 'checked' )  === true ) {
			$body.addClass('slide-nav-open');
			console.log('root checkbox is checked');
		} else {
			$body.removeClass('slide-nav-open');
			$navCheckboxes.prop( 'checked', false );
			console.log('root checkbox is not checked');
		}

	});

});




// navigation-mobile: scroll up on checkbox change

$(document).ready(function () {

	var $navCheckboxes = $('.slide-nav-checkbox');

	$navCheckboxes.on('change', function () {
		$('.slide-nav-root, .slide-nav-root *').scrollTop(0);
	});
});





/*


$(document).ready(function() {

	var labelId;

	$('.pseudo-label').on('click', function(e) {

		console.log('pseudo label was clicked');
       labelId = $(this).children('label').attr('for');

       	$('#'+labelId).trigger('click');

	   	e.preventDefault();
		return false

	});

});
*/


/*

$(document).ready(function() {

	$('.slide-nav-checkbox').focus(function() {

		$checkboxId = $(this).attr('id');

		$('label[for="' + $checkboxId + '"]').addClass('has-focus');

	}).blur(function() {
		$('label[for="' + $checkboxId + '"]').removeClass('has-focus');
	});

});
*/




// .parent('a')



/*
$(document).ready(function () {
    var $navServiceClone = $('nav.service').find('li').clone();
    var $navSocialsClone = $('nav.socials').find('a').clone()
    var $navMobile = $('.slide-nav-root');

    $navServiceClone.addClass('slide-nav-service');
    $navServiceClone.eq(0).addClass('slide-nav-service-first');
    $navServiceClone.eq($navServiceClone.length - 1).addClass('slide-nav-service-last');

    $navSocialsClone.wrapAll('<li class="slide-nav-socials"></li>');

    $navServiceClone.appendTo($navMobile.find('> ul'));
    $navSocialsClone.parent('li').appendTo($navMobile.find('> ul'));
});
*/

/*

$("#demo6").hoverIntent({
	over: makeTall,
	out: makeShort,
	selector: 'li'
});
*/