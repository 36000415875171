$(document).ready(function(){
	
	if( $(".share").length > 0 ){
	
		var currentLocation = encodeURIComponent( $("meta[property='og:url']").attr("content") );
		var currentTitle= encodeURIComponent( $("meta[property='og:title']").attr("content") );			
		var currentSiteName= encodeURIComponent( $("meta[property='og:site_name']").attr("content") );
		var currentDesc= encodeURIComponent( $("meta[property='og:description']").attr("content") );
		
		if(currentDesc == 'undefined') {
			currentDesc = '';
		}
		if (currentSiteName == 'undefined') {
			currentSiteName = '';
		}
	
		$(".share .share__icon--email").attr(
			"href",
			//'mailto:?subject=Empfehlung:' + mailURI
			'mailto:?subject=Empfehlung: ' + currentTitle + ' von ' + currentSiteName + '&body=' + currentTitle + '%0D%0A'+ currentDesc + '%0D%0A%0D%0A' + currentLocation
		);
	}
});