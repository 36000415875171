$(document).ready(function(){

	/*
	$(".media-group.top").isotope(
		{
			itemSelector: '.media',
			layoutMode: 'fitRows'
		}
	);
	
	$(".media-group.above").isotope(
		{
			itemSelector: '.media',
			layoutMode: 'fitRows'
		}
	);
	
	$(".media-group.bottom").isotope(
		{
			itemSelector: '.media',
			layoutMode: 'fitRows'
		}
	);
	*/
	
	
	$(".textrow table").each(
		function() {
			$(this).wrap( '<div class="tableWrapOuter"><div class="tableWrapInner"></div></div>' );
		}
	);
	
	
	
});