
// showing/hiding additional form fields during checkout

$(document).ready(function() {

	if ($("#alt-shipping-toggle").prop("checked") === true) {

		// showing additional form fields
		$(".alt-shipping").show();

		// setting shown form fields to required
		$("input.alt-shipping__required").prop("required", true);
	}

	$("#alt-shipping-toggle").click(function() {
		if ($(this).prop("checked") === true) {

			// showing additional form fields
			$(".alt-shipping").show();

			// setting shown form fields to required
			$("input.alt-shipping__required").prop("required", true);
		} else {

			// removing focus and clearing content of hidden form fields
			$(".alt-shipping input")
				.blur()
				.val("");

			// hiding additional form fields again
			$(".alt-shipping").hide();

			// setting hidden form fields to not required
			$("input.alt-shipping__required").prop("required", false);
		}
	});
});


$(document).ready(function() {

	if ($("#alt-billing-toggle").prop("checked") === true) {

		// showing additional form fields
		$(".alt-billing").show();

		// setting shown form fields to required
		$("input.alt-billing__required").prop("required", true);
	}

	$("#alt-billing-toggle").click(function() {
		if ($(this).prop("checked") === true) {

			// showing additional form fields
			$(".alt-billing").show();

			// setting shown form fields to required
			$("input.alt-billing__required").prop("required", true);
		} else {

			// removing focus and clearing content of hidden form fields
			$(".alt-billing input")
				.blur()
				.val("");

			// hiding additional form fields again
			$(".alt-billing").hide();

			// setting hidden form fields to not required
			$("input.alt-billing__required").prop("required", false);
		}
	});
});





// show "reload cart button" if quantity in cart was changed

$(document).ready(function() {

	$('.item__quantity__input').on('change keyup paste', function () {

		$('.reload-cart').addClass('cart-has-changed');

	});
});


