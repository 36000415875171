
$(document).ready(function(){
	
	$(".footer__zip-search form").on("submit", function( event ){ 
		
		event.preventDefault();
		
		window.location = $(this).attr("action") + "#/search?url&plz=" + $("#inline-search__input").val();
		
	});
	
});