// //=require jquery.sumoselect.js

$(document).ready(function () {

  var lang = {
    de: {
      'PLEASE_CHOOSE': 'Bitte wählen',
      'CHOOSE': 'auswählen',
      'CHOOSE_ALL': 'Alle auswählen',
      'CHOOSED': 'ausgewählt',
      'ALL': 'Alle',
      'SEARCH': 'Suche',
      'NO_RESULTS_FOR': 'Keine Ergebnisse für ',
      'OK': 'OK',
      'CANCEL': 'Abbrechen'
    },
    en: {
      'PLEASE_CHOOSE': 'Please choose',
      'CHOOSE': 'choose',
      'CHOOSE_ALL': 'Choose all',
      'CHOOSED': 'choosed',
      'ALL': 'All',
      'SEARCH': 'Search',
      'NO_RESULTS_FOR': 'No results for ',
      'OK': 'OK',
      'CANCEL': 'Cancel'
    }
  }

  lang = lang[$('html').attr('lang') || 'de'];


  $('select.form-control, .js__sumoselect').each(function () {
    var $this = $(this);

    $this.SumoSelect({
      placeholder: $this.attr('placeholder') || lang['PLEASE_CHOOSE'],
      floatWidth: 0,
	  forceCustomRendering: false,
      nativeOnDevice: ['Android', 'BlackBerry', 'iPhone', 'iPad', 'iPod', 'Opera Mini', 'IEMobile', 'Silk'],
      captionFormat: '{0} ' + lang['CHOOSED'],
      captionFormatAllSelected: $this.attr('data-choose-all') || lang['CHOOSE_ALL'],
      // selectAll: $this.attr('data-select-all') || true,
      selectAll: false,
      // okCancelInMulti: true,
      search: $this.attr('data-search') || false,
      searchText: lang['SEARCH'],
      noMatch: lang['NO_RESULTS_FOR'] + ' "{0}"',
      locale: [
      	lang['OK'],
      	lang['CANCEL'],
      	$this.attr('data-choose-all') || lang['CHOOSE_ALL']
      ],
      triggerChangeCombined: true
    });
  });

});


$(document).ready(function(){


	$("select.select--submit-onchange, .js__select-submit-onchange").on("change", function(){
		//jumpMenu( 'parent',this,0);
		//$(".filter form").submit();

		$(this).parents("form").submit();
	});

});


function jumpMenu(targ,selObj,restore) {
	window.location='http://'+window.location.hostname+'/'+selObj.options[selObj.selectedIndex].value;
	if (restore) selObj.selectedIndex = 0;
}
