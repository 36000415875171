
// Fade in sticky newsletter teaser

$(document).ready(function(){

	var $window = $(window);
	var $newsletterlSticky = $('.newsletter-sticky');

	$window.bind("scroll", function() {

	    if ($(this).scrollTop() > 400) {
	        $newsletterlSticky.addClass('is-visible');
	    } else {
	        $newsletterlSticky.removeClass('is-visible');
	    }

	});

});
