
// Add body class if user is tabbing

function handleFirstTab(e) {
    if (e.keyCode === 9) {
        document.body.classList.add('user-is-tabbing');

        window.removeEventListener('keydown', handleFirstTab);

        // Start listening for mouse usage

        window.addEventListener('mousedown', handleMouseDownOnce);
    }
}

// Remove body class if user is using a mouse

function handleMouseDownOnce() {
    document.body.classList.remove('user-is-tabbing');

    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
}

// Start listening for tab usage

window.addEventListener('keydown', handleFirstTab);
