$(document).ready(function(){


	$('.teaser--masonary').imagesLoaded(function () {

			$(".teaser--masonary").isotope(
				{
					itemSelector: '.teaser__item',
					percentPosition: true,
					stamp: '.stamp',
					masonry: {
						// use outer width of grid-sizer for columnWidth
						// columnWidth: 33.333333%
		  			}
				}
			);
		}
	);

	/*
	$(".teaser--fit-in-row").isotope(
		{
			itemSelector: '.teaser-item',
			layoutMode: 'fitRows'
		}
	);
	*/

	// Match teaser height

	$(function() {
		$('.teaser--sameheight .teaser__wrap').matchHeight({
			byRow: true,
			property: 'height',
			target: null,
			remove: false
		});
	});


	// Teaser Slideshow - 2 columns

	var $teaserSlideshow = $('.teaser--slideshow.teaser--col-2');

	$teaserSlideshow.slick({
		prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Vorheriges</span></button>',
		nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Nächstes</span></button>',
		slidesToShow : 1,
		slidesToScroll: 1,
		arrows : false,
		dots: true,
		slide : '.teaser__item',
		adaptiveHeight: false,
		fade: false,
		speed: 500,
		autoplay : false,
		autoplaySpeed : 5000,
		mobileFirst : true,
		responsive : [
			{
				breakpoint: 960,
				settings : {
					slidesToShow : 2,
					slidesToScroll: 2
				}
			}
		]
	});


	// Teaser Slideshow - 3 columns

	var $teaserSlideshow = $('.teaser--slideshow.teaser--col-3');

	$teaserSlideshow.slick({
		prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Vorheriges</span></button>',
		nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Nächstes</span></button>',
		slidesToShow : 1,
		slidesToScroll: 1,
		arrows : false,
		dots: true,
		slide : '.teaser__item',
		adaptiveHeight: false,
		fade: false,
		speed: 500,
		autoplay : false,
		autoplaySpeed : 5000,
		mobileFirst : true,
		responsive : [
			{
				breakpoint: 640,
				settings : {
					slidesToShow : 2,
					slidesToScroll: 2
				}
			}, {
				breakpoint: 960,
				settings : {
					slidesToShow : 3,
					slidesToScroll: 3
				}
			}
		]
	});


	// Teaser Slideshow - 4 columns

	var $teaserSlideshow = $('.teaser--slideshow.teaser--col-4');

	$teaserSlideshow.slick({
		prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Vorheriges</span></button>',
		nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Nächstes</span></button>',
		slidesToShow : 1,
		slidesToScroll: 1,
		arrows : false,
		dots: true,
		slide : '.teaser__item',
		adaptiveHeight: false,
		fade: false,
		speed: 500,
		autoplay : false,
		autoplaySpeed : 5000,
		mobileFirst : true,
		responsive : [
			{
				breakpoint: 480,
				settings : {
					slidesToShow : 2,
					slidesToScroll: 2
				}
			}, {
				breakpoint: 720,
				settings : {
					slidesToShow : 3,
					slidesToScroll: 3
				}
			}, {
				breakpoint: 960,
				settings : {
					slidesToShow : 4,
					slidesToScroll: 4
				}
			}
		]
	});


	// Teaser Slideshow - 5 columns

	var $teaserSlideshow = $('.teaser--slideshow.teaser--col-5');

	$teaserSlideshow.slick({
		prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Vorheriges</span></button>',
		nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Nächstes</span></button>',
		slidesToShow : 1,
		slidesToScroll: 1,
		arrows : false,
		dots: true,
		slide : '.teaser__item',
		adaptiveHeight: false,
		fade: false,
		speed: 500,
		autoplay : false,
		autoplaySpeed : 5000,
		mobileFirst : true,
		responsive : [
			{
				breakpoint: 480,
				settings : {
					slidesToShow : 2,
					slidesToScroll: 2
				}
			}, {
				breakpoint: 720,
				settings : {
					slidesToShow : 3,
					slidesToScroll: 3
				}
			}, {
				breakpoint: 960,
				settings : {
					slidesToShow : 4,
					slidesToScroll: 4
				}
			}, {
				breakpoint: 1200,
				settings : {
					slidesToShow : 5,
					slidesToScroll: 5
				}
			}
		]
	});


	// Teaser Slideshow - Speaker

	var $teaserSlideshowSpeaker = $('.teaser--slideshow.teaser--speaker');

	$teaserSlideshowSpeaker.slick({
		prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Vorheriges</span></button>',
		nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Nächstes</span></button>',
		slidesToShow : 1,
		slidesToScroll: 1,
		arrows : false,
		dots: true,
		slide : '.teaser__item',
		adaptiveHeight: false,
		fade: false,
		speed: 500,
		autoplay : false,
		autoplaySpeed : 5000,
		mobileFirst : true,
		responsive : [
			{
				breakpoint: 960,
				settings : {
					slidesToShow : 2,
					slidesToScroll: 2
				}
			}
		]
	});
});



