$(document).ready(function() {

	if ($('.header').length > 0) {

	    var $window = $(window);

	    function checkWidth() {

	        var windowsize = $window.width();

	        if (windowsize > 960) {

	            var $header = $('.header');
				var $headerSticky = $('.header__bottom')

			    $headerSticky.elementDirectionAndOffsetByScroll('init', {
			        offsetSmall : $header.outerHeight() - $headerSticky.outerHeight(), // Best practice: header-height - header-small-height
			        offsetOut : $header.outerHeight() * 1 // Best practice: header-height,

			    });

	        } else {

		        var $header = $('.header');
				var $headerSticky = $('.header__middle')

			    $headerSticky.elementDirectionAndOffsetByScroll('init', {
			        offsetSmall : $header.outerHeight() - $headerSticky.outerHeight(), // Best practice: header-height - header-small-height
			        offsetOut : $header.outerHeight() * 1 // Best practice: header-height,

			    });
	        }
	    }

	    checkWidth();

	    $(window).resize(checkWidth);

    }
});