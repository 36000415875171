
// Get Publication Shop Item's Width and set it's Height Accordingly

$(document).ready(pubHeight);
$(window).on('resize',pubHeight);

function pubHeight() {
     $('.shop-detail .publication-wrap').each(function() {
        $(this).height($(this).outerWidth());
    });
}

