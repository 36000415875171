
$(document).ready(function () {

	var $heroSlideshow = $('.hero.hero--stage');

	$heroSlideshow.slick({
	    prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Vorheriges</span></button>',
	    nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Nächstes</span></button>',
	    slidesToShow: 1,
	    slidesToScroll: 1,
	    arrows: false,
	    dots: true,
	    slide: '.hero__item',
	    adaptiveHeight: false,
	    fade: false,
	    speed: 500,
	    autoplay: true,
	    autoplaySpeed: 5000
	});

	var $slideshowPause = $('.js-hero__play');

	$slideshowPause.on('click', function () {

		var $this = $(this);

		$this.toggleClass('hero__play--is-paused');

		if ($this.hasClass('hero__play--is-paused')) {
			$heroSlideshow.slick('slickPause');
			$this.text('Play');
		} else {
			$heroSlideshow.slick('slickPlay');
			$this.text('Pause');
			// $this.blur();
			$heroSlideshow.slick('slickNext');
		}
	});


});