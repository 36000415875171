$(document).ready(function(){

	/*
	$(".filter--toogle__btn").on("click",function(event){

		event.preventDefault();

		$(this).parent().hide();

		var target = $(this).attr("data-target");

		$( target ).show();

	});
	*/


	/*
	$(".filter--toogle__close").on("click",function(event){

		event.preventDefault();

		var target = $(this).attr("data-target");
		$( target ).hide();

		var btn = $(this).attr("data-btn");
		$( btn ).parent().show();

	});
	*/


	/*
	$(".filter__expand").on("click",function(event){

		event.preventDefault();

		$(this).parent(".filter").addClass("is-expanded")

	});
	*/


	/*
	$(".filter__close").on("click",function(event){

		event.preventDefault();

		$(this).parent(".filter").removeClass("is-expanded")

	});
	*/


	$(".js-filter-reset").on("click",function(event){

		event.preventDefault();

		$('.filter input[type="text"]').val("");
		$(".filter select option").removeAttr("selected");
		$(".filter .SumoSelect .options .selected").click();

	});

});