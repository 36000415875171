$(document).ready(function () {

/*
	$('.accordion').each(function () {

		var $accordion = $(this);

		if ($accordion.hasClass("accordion__item--firstopened")) {
			$accordion.find('> .accordion__item').eq(0).addClass("isExpanded");
			// $accordion.find('> .accordion__item .accordion__button button').attr( 'aria-expanded', 'true' );
		}
	});
*/

	$('.accordion__button button').each(function () {

		var $button = $(this);
		var $item = $button.parent().parent('.accordion__item');
		var $panel = $item.children('.accordion__panel');

		    $button.on('click', function (e) {

				if ($item.hasClass('isExpanded')) {
					$button.attr('aria-expanded', 'false');
					$item.removeClass('isExpanded');
					$panel.attr('hidden','');

				} else {
					$button.attr( 'aria-expanded', 'true' );
					$item.addClass('isExpanded');
					$panel.removeAttr('hidden');
				}

				e.preventDefault();
				return false
		});

	});

});