
$(document).ready(function(){

	var $carousel = $('.carousel__wrap');
	var $carousel_status = $('.carousel__status');

	var $carousel_SlidesToShow = 3;
	var $carousel_SlidesToShow_Tablet = 2;
	var $carousel_SlidesToShow_Mobile = 1;

	var $carousel_variableWidth = false;
	var $carousel_adaptiveHeight = false;
	var $carousel_centerMode = false;

	// Getting current slide and total number of slides for a compact replacement of the dot nav on mobile

	$('.carousel__wrap').on('onLoad init reInit afterChange', function (event, slick, currentSlide, nextSlide) {

        // currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)

        var i = (currentSlide ? currentSlide : 0) + 1;

        $carousel_status.html('<span>' + i + '</span>' + '/' + '<span>' + slick.slideCount + '</span>');
    });

	$('.carousel').each(function(){

		// Setting the max numbers of slides shown
		$carousel = $(this);

		if ( $(this).hasClass("carousel--col-1") ) {
			$carousel_SlidesToShow = 1;
			$carousel_SlidesToShow_Tablet = 1;
			$carousel_SlidesToShow_Mobile = 1;
		}

		if ( $(this).hasClass("carousel--col-2") ) {
			$carousel_SlidesToShow = 2;
			$carousel_SlidesToShow_Tablet = 2;
			$carousel_SlidesToShow_Mobile = 1;
		}

		if ( $(this).hasClass("carousel--col-4") ) {
			$carousel_SlidesToShow = 4;
			$carousel_SlidesToShow_Tablet = 3;
			$carousel_SlidesToShow_Mobile = 2;
		}

		if ( $(this).hasClass("carousel--col-5") ) {
			$carousel_SlidesToShow = 5;
			$carousel_SlidesToShow_Tablet = 3;
			$carousel_SlidesToShow_Mobile = 2;
		}

		// Setting the handling of differently sized images

		if ( $(this).hasClass("carousel--variable-width") ) {
			$carousel_variableWidth = true;
		}

		if ( $(this).hasClass("carousel--center-mode") ) {
			$carousel_centerMode = true;
		}

		if ( $(this).hasClass("carousel--adaptive-height") ) {
			$carousel_adaptiveHeight = true;
			$carousel_SlidesToShow = 1;
			$carousel_SlidesToShow_Tablet = 1;
			$carousel_SlidesToShow_Mobile = 1;
		}

		$(".carousel__wrap",this).slick({
			prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Vorheriges</span></button>',
			nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Nächstes</span></button>',
			slidesToShow : $carousel_SlidesToShow,
			slidesToScroll: $carousel_SlidesToShow,
			arrows : true,
			prevArrow: $carousel.find('.carousel__prev'),
			nextArrow: $carousel.find('.carousel__next'),
			dots: true,
			appendDots: $carousel.find(".carousel__dots"),
			slide : '.carousel__item',
			fade: false,
			speed: 500,
			autoplay : false,
			autoplaySpeed : 5000,
			variableWidth : $carousel_variableWidth,
			centerMode: $carousel_centerMode,
			adaptiveHeight: $carousel_adaptiveHeight,

			responsive : [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: $carousel_SlidesToShow_Tablet,
						slidesToScroll: $carousel_SlidesToShow_Tablet
					}
				},
				{
					breakpoint: 720,
					settings: {
						slidesToShow: $carousel_SlidesToShow_Mobile,
						slidesToScroll: $carousel_SlidesToShow_Mobile,
						dots: false,
						variableWidth : false,
						adaptiveHeight : false
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						dots: false,
						variableWidth : false,
						adaptiveHeight : false
					}
				}
			]
		});

		$carousel_SlidesToShow = 3;
		$carousel_SlidesToShow_Tablet = 2;
		$carousel_SlidesToShow_Mobile = 1;
		$carousel_variableWidth = false;
		$carousel_adaptiveHeight = false;
		$carousel_centerMode = false;

	});

});
