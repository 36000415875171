
// Set tabindex -1 on skip link target

// Prevent Safari on iOS to jump back to skip link
// on subsequent "tab" when using voice over

$(document).ready(function(){

	$('.skip-link--content').click(function(e) {

		e.preventDefault();

		$('#content').attr('tabindex', '-1').focus();
	});

});
