$(document).ready(function(){

	var $fbslide = $('.js-facebookstream__slider');

	$fbslide.slick({

		prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Vorheriges</span></button>',
		nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Nächstes</span></button>',
		arrows : false,
		dots: true,
		slide : '.js-facebookstream__item',
		adaptiveHeight: false,
		fade: false,
		speed: 500,
		autoplay : true,
		autoplaySpeed : 5000,
		variableWidth : false,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive : [
			{
			breakpoint: 960,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},{
			breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]

	});

});